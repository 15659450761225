




















































































































































import { Vue, Component } from "vue-property-decorator";
import axios, { AxiosError, AxiosResponse } from "axios";

@Component({
  metaInfo() {
    return {
      title: "Hesap Oluştur"
    };
  }
})
export default class RegisterView extends Vue {
  form = {
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirm: "",
    email: "",
    phone: "",
    tckn: "",
    gender: "",
    eduLevel: "",
    city: "",
    address: "",
    profession: ""
  };

  alert = false;
  message = "";
  error = false;
  confirm = false;

  gender = ["Kadın", "Erkek"];

  eduLevels = ["İlköğretim", "Lise", "Ön Lisans", "Lisans", "Yüksek Lisans", "Doktora"];

  get firstNameRules(): unknown[] {
    return [(v: string) => !!v || "Lütfen adınızı yazınız"];
  }

  get lastNameRules(): unknown[] {
    return [(v: string) => !!v || "Lütfen soyadınızı yazınız"];
  }

  get emailRules(): unknown[] {
    return [
      (v: string) => !!v || "Lütfen e-postanızı yazınız",
      (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
    ];
  }

  get passwordRules(): unknown[] {
    return [
      (v: string) => !!v || "Lütfen parolanızı yazınız",
      (v: string) =>
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*'<>"+/\\()=?\-_|£[\]{}.,éıİöÖçÇşŞğĞüÜ]).{8,}$/g.test(v) ||
        "Parolanız en az 8 karakter olmalıdır. En az 1 büyük harf, 1 küçük harf, 1 sayı ve 1 özel karakter içermelidir."
    ];
  }

  get passwordConfirmRules(): unknown[] {
    return [
      (v: string) => !!v || "Lütfen parolanızı onaylayınız",
      (v: string) => v === this.form.password || "Parola onaylanmadı"
    ];
  }

  get phoneRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen telefon numaranızı yazınız",
      (v: string) =>
        /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/.test(v) || "Geçersiz numara (Örn: 0532 111 11 11)"
    ];
  }

  tcknRules = [
    (v: string) => !!v || "Lütfen TC kimlik numaranızı yazınız",
    (v: string) => /^[1-9]{1}[0-9]{9}[02468]{1}$/.test(v) || "Geçersiz TC kimlik numarası"
  ];

  genderRules = [(v: string) => !!v || "Lütfen cinsiyetinizi belirtiniz"];

  eduLevelRules = [(v: string) => !!v || "Lütfen eğitiminizi belirtiniz"];

  addressRules = [(v: string) => !!v || "Lütfen geçerli bir adres belirtiniz"];

  professionRules = [(v: string) => !!v || "Lütfen mesleğinizi belirtiniz"];

  get confirmRules(): unknown[] {
    return [this.confirm || "Lütfen kullanım koşullarını ve politikalarını onaylayınız."];
  }

  validate(): boolean {
    const form = this.$refs.form as HTMLFormElement;
    return form.validate();
  }

  reset(): void {
    const form = this.$refs.form as HTMLFormElement;
    form.reset();
  }

  resetValidation(): void {
    const form = this.$refs.form as HTMLFormElement;
    form.resetValidation();
  }

  async register(): Promise<void> {
    if (this.validate()) {
      try {
        const res = await axios.post("/api/auth/register", {
          Username: this.form.email,
          Email: this.form.email,
          Password: this.form.password,
          FirstName: this.form.firstName,
          LastName: this.form.lastName,
          Phone: this.form.phone,
          Tckn: this.form.tckn,
          Gender: this.form.gender,
          EduLevel: this.form.eduLevel,
          Profession: this.form.profession,
          Address: this.form.address
        });

        this.error = false;
        this.alert = true;
        this.message = "Kullanıcı kaydınız başarıyla tamamlandı. Giriş sayfasına yönlendiriliyorsunuz...";

        const timer1 = setTimeout(() => {
          //this.$router.push({ name: "Login" });
          this.openLogin();

          clearTimeout(timer1);
        }, 3000);
      } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
          this.message = e.response?.data.message;
        } else {
          this.message = "Beklenmedik bir hata nedeniyle hesabınız oluşturulamadı.";
        }

        this.error = true;
        this.alert = true;
      }
    }
  }

  openLogin(): void {
    var returnUrl = this.$route.query.returnUrl;
    
    if (returnUrl) {
      this.$router.push({ name: 'Login', query: { returnUrl: returnUrl.toString() }});
    } else {
      this.$router.push({ name: 'Login'});
    }
  }

  mounted() {
    this.$emit("setTitle", "Hoş geldiniz!");
  }
}
